import { Component, Input, OnInit, OnChanges, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSourceWithNaturalSort } from '@services/utils/mat-table-data-source-with-natural-sort.service';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { FillOptionsDialog } from '@dialogs/fill-options/fill-options-dialog';

import { SegmentResource } from '@resources/segment-resource.service';

@Component({
    selector: 'kit-missing-items',
    templateUrl: './kit-missing-items.html',
    styleUrls: ['./kit-missing-items.scss'],
})
export class KitMissingItems {
    @Input() items;
    @Input() headerKey;
    @Input() kitSummary;
    @Input() scanId;
    @Input() titleKey;
    @Input() type: string;

    dataSource: MatTableDataSourceWithNaturalSort<any>;
    displayedColumns: string[] = ['segmentValue', 'segment_name', 'expectedText', 'actual'];
    @ViewChild(MatSort) sort: MatSort;
    hasData: boolean;

    constructor(
        private segmentResource: SegmentResource,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.items.forEach((item) => {
            item.expectedText = this.expectedText(item);
            if (this.type === 'missing_items') {
                item.segmentValue = this.missingText(item);
            } else {
                item.segmentValue = item.extra;
            }
        });

        if (this.kitSummary.items.delta_on_right) {
            this.displayedColumns = ['segment_name', 'expectedText', 'actual', 'segmentValue'];
        }
    }

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.items);
        this.hasData = !!this.dataSource.data.length;
        this.dataSource.sort = this.sort;
    }

    ngOnChanges() {
        this.dataSource = new MatTableDataSourceWithNaturalSort(this.items);
        this.hasData = !!this.dataSource.data.length;
        this.dataSource.sort = this.sort;
    }

    segmentTemplateModal = (segment) => {
        this.segmentResource.getSingleSegment(segment.segment_id, this.scanId).then((results) => {
            const dialog = this.dialog.open(FillOptionsDialog, {
                width: '1000px',
                height: 'max-content',
                data: {
                    segment: results.segment,
                },
            });
        });
    };

    missingText(item) {
        const missingMin = item.missing_min.toString() || '';
        const missingMax = item.missing_max.toString() || '';

        if (missingMax === missingMin) {
            return missingMin;
        }
        return `${missingMin} - ${missingMax}`;
    }

    expectedText(item) {
        const shortage = item.shortage || '';
        const expectedMax = item.expected_max.toString() || '';
        const expectedMin = item.expected_min.toString() || '';
        if (shortage) {
            if (expectedMax === expectedMin) {
                return expectedMin;
            }
            return `${expectedMin} - ${expectedMax}`;
        } else {
            return item.expected.toString() || '';
        }
    }
}
