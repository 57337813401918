import { Component } from '@angular/core';
import { TableComponent } from '@components/common/table-component';
import { Container } from '@models/core/container';
import { ContainerResource } from '@resources/container-resource.service';
import { LoadingSpinnerService } from '@services/system/loading-spinner.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'containers-detail',
    templateUrl: './containers-detail.html',
    styleUrls: ['./containers-detail.scss'],
})
export class ContainersDetail extends TableComponent {
    protected tab = 'summary';
    container: Container;
    isCreateContainerSegmentAllowed: boolean;
    isDeleteContainerSegmentAllowed: boolean;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private containerResource: ContainerResource,
        private $state: StateService
    ) {
        super();
    }

    ngOnInit() {
        this.fetchContainerData();
    }

    fetchContainerData() {
        const containerId = this.$state.params?.id;
        if (!containerId) {
            return;
        }
        this.loadingSpinnerService.spinnerifyPromise(
            this.containerResource.getContainer(containerId).then(({ container }) => {
                this.container = container;
            })
        );
    }

    showSpinner() {
        return this.loadingSpinnerService.showSpinner;
    }

    handleOnSegmentDelete() {
        this.fetchContainerData();
    }

    handleOnSegmentSave() {
        this.fetchContainerData();
    }
}
