import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Container, ContainerForm } from '@models/core/container';
import { Segment } from '@models/core/segment';
import { ConfigurationProvider } from '@services/config/configuration';

@Injectable()
export class ContainerResource {
    API_ENDPOINT = this.configuration.kcEndpointV1();

    constructor(
        private configuration: ConfigurationProvider,
        private http: HttpClient
    ) {}

    addContainer(container: ContainerForm) {
        return this.http.post<Container>(`${this.API_ENDPOINT}containers`, container).toPromise();
    }

    addContainerSegment(containerId: number, segment: Segment) {
        return this.http.post<Segment>(`${this.API_ENDPOINT}containers/${containerId}/segments`, segment).toPromise();
    }

    editContainer(containerId: number, container: Partial<ContainerForm>) {
        return this.http.put<Container>(`${this.API_ENDPOINT}containers/${containerId}`, container).toPromise();
    }

    getContainers(type: Container['type']) {
        return this.http
            .get<{ containers: Array<Container> }>(`${this.API_ENDPOINT}containers?type=${type}`)
            .toPromise();
    }

    getContainer(id: number) {
        return this.http.get<{ container: Container }>(`${this.API_ENDPOINT}containers/${id}`).toPromise();
    }

    getContainerSegmentsScanData(containerId: number) {
        return new Promise((resolve) => {
            resolve([
                {
                    drug_name: 'Aspirin',
                    manufacturer: 'Bayer',
                    ndc: '0013-0023-25',
                    epc: 'A12B',
                    strength: '325 mg',
                    package: 'Bottle of 100',
                    lot: 'A123456',
                    expiration: '2025-06-01',
                },
                {
                    drug_name: 'Ibuprofen',
                    manufacturer: 'Advil',
                    ndc: '0057-0194-01',
                    epc: 'C34D',
                    strength: '200 mg',
                    package: 'Bottle of 50',
                    lot: 'B654321',
                    expiration: '2024-12-15',
                },
                {
                    drug_name: 'Amoxicillin',
                    manufacturer: 'Mylan',
                    ndc: '0037-0123-05',
                    epc: 'E56F',
                    strength: '500 mg',
                    package: 'Box of 14 Tablets',
                    lot: 'C789012',
                    expiration: '2023-11-30',
                    expired: true,
                },
                {
                    drug_name: 'Metformin',
                    manufacturer: 'Teva',
                    ndc: '0169-7521-11',
                    epc: 'G78H',
                    strength: '850 mg',
                    package: 'Bottle of 60',
                    lot: 'D345678',
                    expiration: '2025-02-28',
                    recalled: true,
                },
                {
                    drug_name: 'Lisinopril',
                    manufacturer: 'Sandoz',
                    ndc: '0078-0181-20',
                    epc: 'I90J',
                    strength: '10 mg',
                    package: 'Bottle of 30',
                    lot: 'E901234',
                    expiration: '2024-10-10',
                    expiringSoon: true,
                },
            ]);
        });
    }
}
