import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StateService } from '@uirouter/core';

import { ApplicationService } from '@services/system/application.service';
import { ScanningService } from '@services/core/scanning.service';
import { ScanRedirectionService } from '@services/core/scan-redirection.service';

import { Scanner } from '@models/hardware/scanner';
import { ScanError, ScanResult } from '@models/core/scan';

@Component({
    selector: 'scanning-dialog',
    templateUrl: './scanning-dialog.html',
})
export class ScanningDialog implements OnInit {
    currentlyScanning: boolean = true;
    isMobile: boolean = false;
    spinnerMessage: string = 'modals.scan.scanning_your_items';
    scanError: ScanError;

    constructor(
        public dialogRef: MatDialogRef<ScanningDialog>,
        private applicationService: ApplicationService,
        private scanningService: ScanningService,
        private scanRedirectionService: ScanRedirectionService,
        private $state: StateService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            scanner: Scanner;
        }
    ) {}

    ngOnInit() {
        this.isMobile = this.applicationService.isMobile;
        this.performScan();
        window['processScan'] = this.handleScan;
    }

    performScan() {
        this.currentlyScanning = true;
        this.scanningService
            .initiateScan(this.data.scanner)
            .then((scanData) => {
                this.spinnerMessage = 'modals.scan.analyzing_scan_data';
                // If we are using moto V1 scanning, this will be undefined, and processScan is called through the window handler instead
                if (!!scanData) {
                    this.handleScan(scanData);
                }
            })
            .catch((error: ScanError) => {
                this.currentlyScanning = false;
                this.scanError = error;
            });
    }

    reportPretaggedIssue() {
        this.$state.go('admin.pretagged-quarantine');
        this.dialogRef.close();
    }

    processScan(scanData): Promise<any> {
        return this.scanningService.sendScanData(scanData, this.data.scanner.id).then((scanResult: ScanResult) => {
            if (scanResult.scanError !== null) {
                this.currentlyScanning = false;
                throw scanResult.scanError;
            }
            if (!this.isMobile) {
                this.scanRedirectionService.redirectScan(scanResult);
            }
            this.dialogRef.close(scanResult);
        });
    }

    // The moto V1 firmware returns a json script that calls 'processScan' on the window to do it's thing
    handleScan = (scanData) => {
        if (scanData.testScan) {
            this.processScan(scanData.scanData).catch((error: ScanError) => {
                console.log('test scan error', error);
            });
        } else {
            this.processScan(scanData).catch((error: ScanError) => {
                this.currentlyScanning = false;
                if (error.closeScanModal === true) {
                    this.dialogRef.close();
                    return;
                }
                this.scanError = error;
            });
        }
    };
}
